@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=PT+Serif:wght@400;700&display=swap');

// logo and humbergur alignment
.css-x5zaxz {
    align-items: center;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//     font-family: 'PT Serif', serif !important;
//     font-weight: 700 !important;
// }

p{
    margin: 0;
    padding: 0;
}

// loading spinner 
.material-spinner {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    min-height: 50px;
    width: 50px;
    border-radius: 100px;
    border: 5px transparent solid;
    border-top: 5px #2196f3 solid;
    animation: material-spinner 4s infinite;
}

@keyframes material-spinner {
    0% {
        transform: rotate(0deg);
        border-top: 5px #2196f3 solid;
    }

    25% {
        transform: rotate(360deg);
        border-top: 5px #F44336 solid;
    }

    50% {
        transform: rotate(720deg);
        border-top: 5px #3F51B5 solid;
    }

    75% {
        transform: rotate(1080deg);
        border-top: 5px #4CAF50 solid;
    }

    100% {
        transform: rotate(1440deg);
        border-top: 5px #FFC107 solid;
    }
}

// from button loading 
.loading-form-button {
    position: relative;
    padding: 12px 20px;
    height: 44px;
    width: 92px;
    text-align: center;
    color: transparent;
    transition: all 0.4s ease-in-out;

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -4px;
        margin-left: -4px;
        content: '';
        height: 10px;
        width: 10px;
        background: white;
        border-radius: 100%;
        transition: all 0.4s linear;
        animation: test 2s infinite;
        opacity: 1;

    }
}

@keyframes one-two {
    0% {
        left: 30%;
        top: 50%;
    }

    12.5% {
        top: 30%;
    }

    25% {
        top: 50%;
    }

    37.5% {
        top: 70%;
    }

    50% {
        left: 70%;
        top: 50%;
    }

    62.5% {
        top: 30%;
    }

    75% {
        top: 50%;
    }

    87.5% {
        top: 70%;
    }

    100% {
        left: 30%;
        top: 50%;
    }
}

@keyframes test {
    0% {
        left: 30%;
    }

    50% {
        left: 70%;
    }

    100% {
        left: 30%;
    }
}

// form label color
form label {
    color: #7c7a7a !important;
    font-weight: 500 !important;
}

label.MuiButton-root {
    border-radius: 7px;
}

label.MuiButton-containedPrimary {
    color: #ffffff !important;
}

.dark-tabs .MuiTabs-flexContainer {
    border-bottom: 0px;
}

.dark-tabs .MuiTabs-indicator {
    border-bottom: 2px solid #f2f2f2;
}

// calendar grid customize
.attendances {
    background-color: #ccffce !important;
    opacity: 1 !important;
    color: #000000 !important;
    border: 0px !important;
    padding: 5px;
    text-align: left;
    text-overflow: ellipsis;
    // display: block;
}

.holidays {
    background-color: #c4f1ff !important;
    opacity: 1 !important;
    color: #000000 !important;
    border: 0px !important;
    padding: 5px !important;
    text-overflow: ellipsis;

    // display: block;
    .fc-event-title {
        color: #000000 !important;
        font-weight: bold;
        text-overflow: ellipsis;
        // display: block;
    }
}

.leaves {
    background-color: #f8977e !important;
    opacity: 1 !important;
    color: #000000 !important;
    border: 0px !important;
    padding: 5px !important;
    text-overflow: ellipsis;
    // display: block;
}

button.fc-today-button.fc-button.fc-button-primary {
    text-transform: capitalize;
}

.fc-event-time,
.fc-list-event-time,
.fc-daygrid-event-dot,
.fc-list-event-dot {
    display: none !important;
}

.fc-h-event .fc-event-main {
    color: #000000 !important;
}

.fc-button-group button {
    text-transform: capitalize !important;
    box-shadow: none !important;
}

.fc-button-group button:focus {
    box-shadow: none !important;
}

.fc-list-event-title:hover {
    background-color: 'black' !important;
}

// listing table body
// .MuiTableContainer-root.css-rorn0c-MuiTableContainer-root {
//     min-height: 320px;
// }

td,
th {
    padding: 10px 12px !important;
}

.tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 8px;
    border: 1px solid #bdbdbd;
    border-radius: 7px;

    &:hover {
        border: 1px solid #616161;
    }

    &:focus-within {
        border: 1px solid #a80b44;
    }

    input {
        flex: 1;
        border: none;
        height: 46px;
        font-size: 14px;
        padding: 4px 0 0 0;

        &:focus {
            outline: transparent;
        }

        &::placeholder {
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
        }
    }
}

// text editor
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
    min-height: 150px;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable:focus,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners:focus {
    border-color: #a80b44 !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px !important;
}

 .MuiTabScrollButton-root{
    display: none;
}